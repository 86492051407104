import {forThisUrl} from "../middleware";
import {ResponseCheckUuidtransaction} from "../model";
import {MobileMoneyForm, WaveForm} from "../model/payement";


export class GuichetUniqueService {
    private readonly API_URL = process.env.REACT_APP_COPAY_API || '';
    private readonly API_URL_AWS = process.env.REACT_APP_COPAY_API_AWS || '';
    private X_COUNTRY = 'SN';

    private get api() {
        return forThisUrl(this.API_URL).getAxiosInstance()
    }

    private get api_aws() {
        return forThisUrl(this.API_URL_AWS).getAxiosInstance()
    }

    checkMalitelPayment(uuid: string) {
        return this.api.get("/check-malitel-payment/" + uuid, {
            headers: {
                'X-COUNTRY': this.X_COUNTRY
            }
        });
    }

    checkMoovTchadPayment(uuid: string) {
        return this.api_aws.get("/check-moovtchad-payment/" + uuid, {
            headers: {
                'X-COUNTRY': this.X_COUNTRY
            }
        });
    }

    checkWalletPayment(uuid: string) {
        return this.api.get("/check-wave-payment/" + uuid, {
            headers: {
                'X-COUNTRY': this.X_COUNTRY
            }
        });
    }

    async checkUuid(uuid: string, country: string) {
        // if (uuid.split('.').length == 2) {
        //     this.X_COUNTRY = uuid.split('.')[0];
        //     uuid = uuid.split('.')[1];
        // }
        this.X_COUNTRY = country;
        return this.api.get<ResponseCheckUuidtransaction>("/checkout-uuid-transaction/" + uuid, {
            headers: {
                'X-COUNTRY': this.X_COUNTRY
            }
        });
    }

    proccedPayment(payement: MobileMoneyForm) {
        if (this.X_COUNTRY == 'TD') {
            return this.api_aws.post("/procced-payment", payement, {
                headers: {
                    'X-COUNTRY': this.X_COUNTRY
                }
            });
        } else {
            return this.api.post("/procced-payment", payement, {
                headers: {
                    'X-COUNTRY': this.X_COUNTRY
                }
            });
        }
    }

    initWavePayment(payement: WaveForm) {
        return this.api.post("/init-wave-payment", payement, {
            headers: {
                'X-COUNTRY': this.X_COUNTRY
            }
        });
    }
}
