import {PHONE, TOGO} from "../../../assets";
import {useForm, Resolver} from "react-hook-form";
import {MobileMoneyProps} from "./MobileMoneyProps";
import {ResponseCheckUuidtransaction} from "../../../model";
import {useMobileMoney} from "./useMobileMoney";
import {useEffect, useState} from "react";

const MobileMoney: React.FC<MobileMoneyProps> = ({transaction}) => {
    const {onSubmitMobile, loadingMobileMoney} = useMobileMoney();
    const [paysSelected, setPaysSelected] = useState(false);
    const [isoPaysSelected, setIsoPaysSelected] = useState("");
    const [moyenPaiementSelected, setMoyenPaiementSelected] = useState(false);
    const [serviceSelected, setServiceSelected] = useState("");

    type FormValues = {
        phone: string;
        code: string;
        pays: string;
        service: string
    };

    const getPays = (transaction: ResponseCheckUuidtransaction | undefined): any[] => {
        let pays: any[] = [];
        let paysExist: string[];
        if (transaction?.services?.services?.length) {
            pays = [];
            paysExist = [];
            transaction?.services?.services.map((ser) => {
                if (ser?.pays?.iso && !paysExist.includes(ser?.pays?.iso)) {
                    paysExist.push(ser?.pays?.iso);
                    pays.push({
                        label: ser?.pays?.nom,
                        value: ser?.pays?.iso
                    })
                }
            })
        }
        return pays;
    }
    const getService = (transaction: ResponseCheckUuidtransaction | undefined): any[] => {
        let service: any[] = [];
        if (transaction?.services?.services?.length) {
            service = [];
            transaction?.services?.services.map((ser) => {
                if (ser?.typeService === "Mobile Money") {
                    service.push({
                        label: ser?.nom,
                        value: ser?.code,
                        pays: ser?.pays?.iso,
                    })
                }
            })
        }
        return service;
    }
    const resolver: Resolver<FormValues> = async (values) => {
        return {
            values: values.phone && values.phone && values.code && values.pays && values.service ? values : {},
            errors: checkError(values)
        };
    };
    const checkError = (data: FormValues) => {
        let response = {};
        if (!data?.pays) {
            response = {
                ...response, pays: {
                    type: 'required',
                    message: 'Select PAYS SVP!',
                }
            }
        }
        if (!data?.service) {
            response = {
                ...response, service: {
                    type: 'required',
                    message: 'Select Service SVP!',
                }
            }
        }
        if (serviceSelected != 'COPOrangeMoneyMali') {
            if (!data?.phone) {
                response = {
                    ...response, phone: {
                        type: 'required',
                        message: 'Veuiller renseigner votre numéro',
                    }
                }
            }
        }
        if (serviceSelected == 'COPOrangeMoney') {
            if (!data?.code) {
                response = {
                    ...response, code: {
                        type: 'required',
                        message: 'Veuiller remplir ce champ',
                    }
                }
            }
        }
        return response;
    }
    const {register, handleSubmit, reset, formState: {errors}} = useForm<FormValues>({
        defaultValues: {
            phone: '', // Set your default values here
            code: '',
            pays: '',
            service: '',
        }, resolver: resolver
    });

    function paysChanged(e: any) {
        if (e.target.value !== "") {
            setIsoPaysSelected(e.target.value);
            setPaysSelected(true);
        } else {
            setIsoPaysSelected("");
            setPaysSelected(false);
        }
    }

    function serviceChanged(e: any) {
        if (e.target.value !== "") {
            setMoyenPaiementSelected(true);
            setServiceSelected(e.target.value);
            if (e.target.value === "COPOrangeMoney")
                reset({phone: '', code: ''});
            else if (e.target.value === "COPMoovMoney" || e.target.value === "COPMoovTchad")
                reset({phone: '', code: '0000'});
            else if (e.target.value === "COPOrangeMoneyMali")
                reset({phone: 'inconnu', code: '0000'});
        } else {
            setMoyenPaiementSelected(false);
        }
    }

    useEffect(() => {
        console.clear();
    }, []);

    const onsubmit = handleSubmit(onSubmitMobile);
    // const onSubmit = handleSubmit((values)=>{
    //     console.log(values);
    // });
    // const onSubmit = (values: any) => {
    //     handleSubmit(values);
    //     onSubmitMobile(values);
    //     // Votre logique de traitement ici
    // };
    return (
        <form onSubmit={onsubmit}>
            {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
            <div className="row">
                <div className={paysSelected ? "col-sm-6" : "col-sm-12"}>
                    <select {...register("pays")} className="form-select form-select-lg mb-3 select-copay"
                            aria-label=".form-select-lg example" onChange={paysChanged}>
                        <option value="">Choix du pays</option>
                        {transaction?.services && getPays(transaction)?.map((pay, index) => {

                            return (<option value={pay.value}>
                                <div className="imgoption"><img src={TOGO}/>{pay.label} </div>
                            </option>)
                        })
                        }
                    </select>
                    {errors?.pays && <p className="errorMessage text-center">{errors.pays.message}</p>}
                </div>
                <div className={paysSelected ? "col-sm-6" : "col-sm-6 d-none"}>
                    <select {...register("service")} className="form-select form-select-lg mb-3 select-copay"
                            aria-label=".form-select-lg example" onChange={serviceChanged}>

                        <option value="">Choix du service</option>
                        {transaction?.services && getService(transaction)?.map((pay, index) => {
                            if (pay.pays === isoPaysSelected) {
                                return (<option value={pay.value}>
                                    <div className="imgoption"><img src={TOGO}/>{pay.label} </div>
                                </option>)
                            }
                        })}
                    </select>
                    {errors?.service && <p className="errorMessage text-center">{errors.service.message}</p>}
                </div>
            </div>
            <div className="mobile-money-options">
                {transaction?.services && getService(transaction)?.map((pay, index) => {
                    if (paysSelected && moyenPaiementSelected && pay.pays === isoPaysSelected && serviceSelected === pay.value) {
                        switch (pay.value) {
                            case 'COPOrangeMoney':
                                return (
                                    <div id={pay.value}
                                         className="moyen-de-payment">
                                        <div className="row mt-2">
                                            <div className="col-sm-12">
                                                <div className="input-copay-group mb-3">
                                                    <div className="prepend-copay">
                                                        <span id="basic-addon1"><img src={PHONE} style={{height: 24}}/></span>
                                                    </div>
                                                    <input  {...register("phone")} type="number"
                                                            className="form-control input-copay"
                                                            placeholder="Numéro de téléphone" aria-label="Username"
                                                            aria-describedby="basic-addon1"/>
                                                    {errors?.phone &&
                                                        <p className="errorMessage text-center">{errors.phone.message}</p>}
                                                </div>
                                                <div className="mt-2">
                                                    <div className="container-alert-message alert-message mt-">
                                                        Taper #144#391*motdepasse# pour obtenir le code temporaire
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div className="input-copay-group mb-3">
                                                    <div className="prepend-copay">
                                                        <span className="" id="basic-addon1"
                                                              style={{fontSize: 24}}>#</span>
                                                    </div>
                                                    <input  {...register("code")} type="number"
                                                            className="form-control input-copay"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Code de validation"/>
                                                    {errors?.code &&
                                                        <p className="errorMessage text-center">{errors.code.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button disabled={loadingMobileMoney} type="submit"
                                                        className="btn-copay"
                                                        style={{height: "54px"}}>
                                                    <span>{!loadingMobileMoney ? "PAYER " + transaction?.transaction.montantPaye + " XOF" : 'Paiement en cours ...'}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'COPMoovMoney':
                            case 'COPMoovTchad':
                                return (
                                    <div id={pay.value}
                                         className="moyen-de-payment">
                                        <div className="row mt-2">
                                            <div className="col-sm-12">
                                                <div className="input-copay-group mb-3">
                                                    <div className="prepend-copay">
                                                        <span id="basic-addon1"><img src={PHONE} style={{height: 24}}/></span>
                                                    </div>
                                                    <input  {...register("phone")} type="number"
                                                            className="form-control input-copay"
                                                            placeholder="Numéro de téléphone" aria-label="Username"
                                                            aria-describedby="basic-addon1"/>
                                                    {errors?.phone &&
                                                        <p className="errorMessage text-center">{errors.phone.message}</p>}
                                                </div>
                                                <div className="mt-2 mb-3">
                                                    <div className="container-alert-message alert-message mt-">
                                                        Veuillez confirmer le paiement une fois votre numéro validé
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button disabled={loadingMobileMoney} type="submit"
                                                        className="btn-copay"
                                                        style={{height: "54px"}}>
                                                    <span>{!loadingMobileMoney ? "PAYER " + transaction?.transaction.montantPaye + " XOF" : 'En attente de votre validation ...'}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'COPOrangeMoneyMali':
                                return (
                                    <div id={pay.value}
                                         className="moyen-de-payment">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button disabled={loadingMobileMoney} type="submit"
                                                        className="btn-copay"
                                                        style={{height: "54px"}}>
                                                    <span>{!loadingMobileMoney ? "PAYER " + transaction?.transaction.montantPaye + " XOF" : 'En attente de redirection ...'}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return (<></>);
                        }
                    }
                    /*
                    return (
                        (paysSelected && moyenPaiementSelected && pay.pays === isoPaysSelected && serviceSelected === pay.value) ?
                            <div id={pay.value}
                                 className="moyen-de-payment">
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <div className="input-copay-group mb-3">
                                            <div className="prepend-copay">
                                                <span id="basic-addon1"><img src={PHONE} style={{height: 24}}/></span>
                                            </div>
                                            <input  {...register("phone")} type="number"
                                                    className="form-control input-copay"
                                                    placeholder="Numéro de téléphone" aria-label="Username"
                                                    aria-describedby="basic-addon1"/>
                                            {errors?.phone && <p className="errorMessage text-center">{errors.phone.message}</p>}
                                        </div>
                                        <div className="mt-2">
                                            <div className="container-alert-message alert-message mt-">
                                                Taper #144#391*motdepasse# pour obtenir le code temporaire
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12">
                                        <div className="input-copay-group mb-3">
                                            <div className="prepend-copay">
                                                <span className="" id="basic-addon1" style={{fontSize: 24}}>#</span>
                                            </div>
                                            <input  {...register("code")} type="number"
                                                    className="form-control input-copay"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Code de validation"/>
                                            {errors?.code && <p className="errorMessage text-center">{errors.code.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button disabled={loadingMobileMoney} type="submit" className="btn-copay"
                                                style={{height: "54px"}}>
                                            <span>{!loadingMobileMoney ? "PAYER " + transaction?.transaction.montantPaye + " XOF" : 'Paiement en cours ...'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div> : <></>)
                    */
                })
                }
            </div>
        </form>
    )
}
export default MobileMoney;